'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _helpers = require('../../../shared/currencyConversion/helpers');

var _ComparedVehicleType = require('../../../types/ComparedVehicleType');

var _CompareTypes = require('./CompareTypes');

var _DataRow = require('./Shared/DataRow');

var _DataRow2 = _interopRequireDefault(_DataRow);

var _GermanTaxDisclaimer = require('../../../components/RollsRoyce/CustomCountryComponents/GermanTaxDisclaimer');

var _GermanTaxDisclaimer2 = _interopRequireDefault(_GermanTaxDisclaimer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Col = _styledComponents2.default.div.withConfig({
  displayName: 'PricingRow__Col',
  componentId: 'sc-ibfk9a-0'
})(['display:flex;flex-direction:column;align-items:start;justify-content:center;margin:10px 0;']);

var Data = _styledComponents2.default.div.withConfig({
  displayName: 'PricingRow__Data',
  componentId: 'sc-ibfk9a-1'
})(['line-height:20px;display:flex;']);

var PricingRow = function PricingRow(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      translations = _ref.translations,
      infoFont = _ref.infoFont,
      currentLanguage = _ref.currentLanguage,
      handleZeroPriceAsText = _ref.handleZeroPriceAsText,
      specialOfferStyling = _ref.specialOfferStyling;

  var hasNetPrice = function hasNetPrice(vehicle) {
    return vehicle.netPrice !== undefined && vehicle.netPrice > 0;
  };

  var getGrossPrice = function getGrossPrice(vehicle) {
    return (0, _helpers.handleVehiclePrice)(vehicle.price.value, {
      currencyType: vehicle.price.currency,
      locale: currentLanguage.replace('_', '-'),
      handleZeroPriceAsText: handleZeroPriceAsText,
      priceSpecial: vehicle.priceSpecial,
      translationsSold: translations.sold,
      vehiclePrice: vehicle.price.value,
      zeroPriceText: translations.zeroPriceText,
      translations: translations,
      retailerCountryCode: vehicle.retailerCountryCode
    });
  };

  var getNetPrice = function getNetPrice(vehicle) {
    return (0, _helpers.handleVehiclePrice)(vehicle.netPrice, {
      currencyType: vehicle.price.currency,
      locale: currentLanguage.replace('_', '-'),
      handleZeroPriceAsText: handleZeroPriceAsText,
      priceSpecial: vehicle.priceSpecial,
      translationsSold: translations.sold,
      vehiclePrice: vehicle.price.value,
      zeroPriceText: translations.zeroPriceText
    });
  };

  var getTax = function getTax(vehicle) {
    var taxValue = vehicle.price.value - vehicle.netPrice;
    return (0, _helpers.handleVehiclePrice)(taxValue, {
      currencyType: vehicle.price.currency,
      locale: currentLanguage.replace('_', '-'),
      handleZeroPriceAsText: handleZeroPriceAsText,
      priceSpecial: vehicle.priceSpecial,
      translationsSold: translations.sold,
      vehiclePrice: vehicle.price.value,
      zeroPriceText: translations.zeroPriceText
    });
  };

  var displayPricing = function displayPricing(vehicle) {
    var showSalesTaxDisclaimer = vehicle.sectionSalesTaxDisclaimer === 1;

    var showNetPrice = hasNetPrice(vehicle);
    return _react2.default.createElement(
      _react.Fragment,
      null,
      showNetPrice || showSalesTaxDisclaimer ? _react2.default.createElement(
        Col,
        null,
        showNetPrice && _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            Data,
            null,
            translations.netPrice,
            ': ',
            getNetPrice(vehicle)
          ),
          _react2.default.createElement(
            Data,
            null,
            translations.tax,
            ': ',
            getTax(vehicle)
          )
        ),
        _react2.default.createElement(
          Data,
          null,
          translations.grossPrice,
          ': ',
          getGrossPrice(vehicle),
          _react2.default.createElement(_GermanTaxDisclaimer2.default, {
            countryCode: vehicle.retailerCountryCode,
            showSalesTaxDisclaimer: showSalesTaxDisclaimer,
            salesTaxDisclaimer: translations.salesTaxDisclaimer,
            styleOverride: function styleOverride() {
              return '\n                  margin: -1px 0 0 2px ;\n                ';
            }
          })
        )
      ) : _react2.default.createElement(
        Col,
        null,
        _react2.default.createElement(
          Data,
          null,
          getGrossPrice(vehicle),
          ' '
        )
      )
    );
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(_DataRow2.default, {
      label: translations.pricing,
      comparedVehicles: comparedVehicles,
      font: infoFont,
      displayFunc: function displayFunc(vehicle) {
        return displayPricing(vehicle);
      },
      specialOfferStyling: specialOfferStyling,
      currentLanguage: currentLanguage,
      translations: translations
    })
  );
};

exports.default = PricingRow;