'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    align-self: flex-start;\n    margin-bottom: 15px;\n  '], ['\n    align-self: flex-start;\n    margin-bottom: 15px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _useIsWindows = require('../../../hooks/useIsWindows');

var _useIsWindows2 = _interopRequireDefault(_useIsWindows);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ProvenanceCollection = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleLabel__ProvenanceCollection',
  componentId: 'sc-12wqc4x-0'
})(['background-color:#676776;color:#fff;font-family:\'RivieraNights-Regular\';font-size:12px;text-transform:uppercase;line-height:1.5;letter-spacing:0.5px;text-align:center;padding:6px 14px;margin-bottom:', ';', ';'], function (_ref) {
  var isVdp = _ref.isVdp;
  return isVdp ? '10px' : '0';
}, _RollsRoyce2.default.min.extraLarge(_templateObject));

var ProvenanceCollectionSpan = _styledComponents2.default.span.withConfig({
  displayName: 'VehicleLabel__ProvenanceCollectionSpan',
  componentId: 'sc-12wqc4x-1'
})(['', ';'], function (_ref2) {
  var isWindows = _ref2.isWindows;
  return isWindows && 'margin-bottom: -4px;';
});

var VehicleLabel = function VehicleLabel(_ref3) {
  var translations = _ref3.translations,
      bespoke = _ref3.bespoke,
      modelCollectionName = _ref3.modelCollectionName,
      isVdp = _ref3.isVdp;

  var isWindows = (0, _useIsWindows2.default)();

  var content = modelCollectionName ? modelCollectionName : bespoke === 1 ? translations.labelBespoke : null;

  return content && _react2.default.createElement(
    ProvenanceCollection,
    { isVdp: isVdp },
    _react2.default.createElement(
      ProvenanceCollectionSpan,
      { isWindows: isWindows },
      content
    )
  );
};

exports.default = VehicleLabel;