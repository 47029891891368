'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = DatePicker;

var _dateFns = require('date-fns');

var _dayzed = require('dayzed');

var _dayzed2 = _interopRequireDefault(_dayzed);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Chunky = require('./JaguarIcons/Arrows/Chunky');

var _Chunky2 = _interopRequireDefault(_Chunky);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'DatePicker__Container',
  componentId: 'sc-1dexxe-0'
})(['']);


var NavigationContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DatePicker__NavigationContainer',
  componentId: 'sc-1dexxe-1'
})(['padding-left:10px;padding-right:10px;padding-bottom:18px;']);

var NavigationButton = _styledComponents2.default.button.withConfig({
  displayName: 'DatePicker__NavigationButton',
  componentId: 'sc-1dexxe-2'
})(['color:', ';font-weight:600;cursor:pointer;border:none;', ';'], function (_ref) {
  var colour = _ref.colour;
  return colour;
}, function (_ref2) {
  var navigationButton = _ref2.navigationButton;
  return navigationButton.background && 'background: ' + navigationButton.background;
});

var WeekDaysContainer = _styledComponents2.default.div.withConfig({
  displayName: 'DatePicker__WeekDaysContainer',
  componentId: 'sc-1dexxe-3'
})(['width:100%;display:flex;border-top:1px solid #d8d8d8;']);

var WeekDaysHeaderCell = _styledComponents2.default.div.withConfig({
  displayName: 'DatePicker__WeekDaysHeaderCell',
  componentId: 'sc-1dexxe-4'
})(['display:inline-block;width:', '%;padding:6px 6px;border:none;text-align:center;color:#b9c1cb;font-size:10px;box-shadow:inset 0 0 0 0 #d2d8df,inset 0 -1px 0 0 #d2d8df,inset -1px 0 0 0 #d2d8df;background:#ffffff;box-sizing:border-box;'], 100 / 7);

var EmptyDayCell = _styledComponents2.default.div.withConfig({
  displayName: 'DatePicker__EmptyDayCell',
  componentId: 'sc-1dexxe-5'
})(['display:inline-block;width:', '%;border:none;box-shadow:inset 1px -1px 0 0 #d2d8df,inset -1px 0 1px 0 #d2d8df;background:#ffffff;box-sizing:border-box;'], 100 / 7);

var DayCell = _styledComponents2.default.button.withConfig({
  displayName: 'DatePicker__DayCell',
  componentId: 'sc-1dexxe-6'
})(['display:inline-block;width:', '%;border:none;box-shadow:inset 0 -1px 0 0 #d2d8df,inset -1px 0 1px 0 #d2d8df;padding:6px 6px;text-align:center;cursor:pointer;font-size:12px;box-sizing:border-box;'], 100 / 7);

var DayCellExpired = _styledComponents2.default.div.withConfig({
  displayName: 'DatePicker__DayCellExpired',
  componentId: 'sc-1dexxe-7'
})(['color:#d8d8d8;']);

var MonthYearHeader = _styledComponents2.default.div.withConfig({
  displayName: 'DatePicker__MonthYearHeader',
  componentId: 'sc-1dexxe-8'
})(['width:100%;text-align:center;font-size:14px;']);

var defaultSelectableDaysOfWeek = {
  sunday: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true
};

function DatePicker(_ref3) {
  var onDateSelected = _ref3.onDateSelected,
      selected = _ref3.selected,
      date = _ref3.date,
      calendarStyles = _ref3.calendarStyles,
      colours = _ref3.globalStyling.colours,
      translations = _ref3.translations,
      _ref3$selectableDaysO = _ref3.selectableDaysOfWeek,
      selectableDaysOfWeek = _ref3$selectableDaysO === undefined ? defaultSelectableDaysOfWeek : _ref3$selectableDaysO;

  var emptyDayCellId = 'emptyDayCellId';
  var dayCellId = 'dayCellId';
  var weekDaysCellId = 'weekDaysCellId';
  var excludeYesterday = (0, _dateFns.subDays)(new Date(), 1);

  var _ref4 = calendarStyles || '',
      _ref4$navigationButto = _ref4.navigationButton,
      navigationButton = _ref4$navigationButto === undefined ? '' : _ref4$navigationButto;

  var monthNames = [translations.january, translations.february, translations.march, translations.april, translations.may, translations.june, translations.july, translations.august, translations.september, translations.october, translations.november, translations.december];

  var weekdayNamesShort = [translations.sunday.substring(0, 1), translations.monday.substring(0, 1), translations.tuesday.substring(0, 1), translations.wednesday.substring(0, 1), translations.thursday.substring(0, 1), translations.friday.substring(0, 1), translations.saturday.substring(0, 1)];

  var primaryBrandColour = colours.primaryBrandColour && colours.primaryBrandColour.value;
  return _react2.default.createElement(_dayzed2.default, {
    onDateSelected: onDateSelected,
    minDate: excludeYesterday,
    selected: selected,
    render: function render(_ref5) {
      var calendars = _ref5.calendars,
          getBackProps = _ref5.getBackProps,
          getForwardProps = _ref5.getForwardProps,
          getDateProps = _ref5.getDateProps;

      if (calendars.length) {
        return _react2.default.createElement(
          Container,
          null,
          calendars.map(function (calendar) {
            return _react2.default.createElement(
              'div',
              { key: '' + calendar.month + calendar.year },
              _react2.default.createElement(
                NavigationContainer,
                null,
                _react2.default.createElement(
                  NavigationButton,
                  (0, _extends3.default)({
                    colour: primaryBrandColour,
                    style: { float: 'left' }
                  }, getBackProps({ calendars: calendars }), {
                    navigationButton: navigationButton
                  }),
                  _react2.default.createElement(
                    'div',
                    { style: { transform: 'rotateY(180deg)' } },
                    _react2.default.createElement(_Chunky2.default, { colour: primaryBrandColour })
                  )
                ),
                _react2.default.createElement(
                  NavigationButton,
                  (0, _extends3.default)({
                    style: { float: 'right' }
                  }, getForwardProps({ calendars: calendars }), {
                    navigationButton: navigationButton
                  }),
                  _react2.default.createElement(
                    'div',
                    null,
                    _react2.default.createElement(_Chunky2.default, { colour: primaryBrandColour })
                  )
                ),
                _react2.default.createElement(
                  MonthYearHeader,
                  null,
                  monthNames[calendar.month],
                  ' ',
                  calendar.year
                )
              ),
              _react2.default.createElement(
                WeekDaysContainer,
                null,
                weekdayNamesShort.map(function (weekday) {
                  weekDaysCellId += 1;
                  return _react2.default.createElement(
                    WeekDaysHeaderCell,
                    {
                      key: '' + calendar.month + calendar.year + weekday + weekDaysCellId
                    },
                    weekday
                  );
                })
              ),
              calendar.weeks.map(function (week) {
                return week.map(function (dateObj) {
                  emptyDayCellId += 1;
                  if (!dateObj) {
                    return _react2.default.createElement(
                      EmptyDayCell,
                      {
                        key: '' + calendar.year + calendar.month + emptyDayCellId
                      },
                      ' '
                    );
                  }
                  var initialDate = dateObj.date,
                      selectable = dateObj.selectable,
                      today = dateObj.today;


                  var isSelected = date.getDate() === initialDate.getDate();
                  var todayTextColour = today ? primaryBrandColour : '#67727D';
                  var selectedTextColour = isSelected ? '#FFFFFF' : '#67727D';
                  var textColour = isSelected ? selectedTextColour : todayTextColour;
                  var selectedDayBackgroundColour = isSelected ? primaryBrandColour : '#FFFFFF';

                  dayCellId += 1;

                  var isDaySelectable = (0, _values2.default)(selectableDaysOfWeek)[initialDate.getDay()];

                  return _react2.default.createElement(
                    DayCell,
                    (0, _extends3.default)({
                      style: {
                        backgroundColor: selectedDayBackgroundColour,
                        color: textColour
                      },
                      disabled: !isDaySelectable,
                      key: '' + calendar.year + calendar.month + dayCellId
                    }, isDaySelectable ? (0, _extends3.default)({}, getDateProps({ dateObj: dateObj })) : {}),
                    selectable && isDaySelectable ? initialDate.getDate() : _react2.default.createElement(
                      DayCellExpired,
                      null,
                      ' ',
                      initialDate.getDate(),
                      ' '
                    )
                  );
                });
              })
            );
          })
        );
      }
      return null;
    }
  });
}