'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AtaGlance = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n   padding: 100px 0 40px;\n  '], ['\n   padding: 100px 0 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n     flex: 1 1 30%;\n    margin-bottom: initial;\n  '], ['\n     flex: 1 1 30%;\n    margin-bottom: initial;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    flex: 1 1 160px;\n    margin-bottom: initial;\n  '], ['\n    flex: 1 1 160px;\n    margin-bottom: initial;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 22px;\n  '], ['\n    font-size: 22px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 18px;\n  '], ['\n    font-size: 18px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n      padding-top: 100px;\n      width: 268px;\n  '], ['\n      padding-top: 100px;\n      width: 268px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _vehicleTypes = require('../../../../types/RollsRoyce/vehicleTypes');

var _types = require('../types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ColumnContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AtaGlance__ColumnContainer',
  componentId: 'sc-3tff4a-0'
})(['display:flex;width:100%;flex-direction:column;justify-content:center;']);

var RowContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AtaGlance__RowContainer',
  componentId: 'sc-3tff4a-1'
})(['display:flex;width:100%;flex-wrap:wrap;']);

var HeadingContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AtaGlance__HeadingContainer',
  componentId: 'sc-3tff4a-2'
})(['display:flex;width:100%;justify-content:center;padding:50px 0;font-size:22px;', ';'], _RollsRoyce2.default.min.medium(_templateObject));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'AtaGlance__Item',
  componentId: 'sc-3tff4a-3'
})(['display:flex;flex-direction:column;align-items:center;justify-content:top;flex:1 1 40%;padding:0 10px;text-align:center;margin-bottom:20px;', ';', ';'], _RollsRoyce2.default.min.small(_templateObject2), _RollsRoyce2.default.min.medium(_templateObject3));

var ItemImage = _styledComponents2.default.img.withConfig({
  displayName: 'AtaGlance__ItemImage',
  componentId: 'sc-3tff4a-4'
})(['height:50px;']);

var TitleHeader = _styledComponents2.default.h2.withConfig({
  displayName: 'AtaGlance__TitleHeader',
  componentId: 'sc-3tff4a-5'
})(['font-family:\'RivieraNights-Light\',\'Jost-Lite\';margin:0;font-size:32px;letter-spacing:2px;text-transform:uppercase;color:#151515;font-weight:300;', ';'], _RollsRoyce2.default.max.medium(_templateObject4));

var ItemHeader = _styledComponents2.default.h3.withConfig({
  displayName: 'AtaGlance__ItemHeader',
  componentId: 'sc-3tff4a-6'
})(['font-family:\'RivieraNights-Regular\',\'Jost-Regular\';font-weight:400;margin:20px 0;font-size:18px;letter-spacing:2px;text-transform:uppercase;color:#151515;', ';'], _RollsRoyce2.default.max.medium(_templateObject5));

var ItemSubHeader = _styledComponents2.default.h3.withConfig({
  displayName: 'AtaGlance__ItemSubHeader',
  componentId: 'sc-3tff4a-7'
})(['font-family:\'RivieraNights-Regular\',\'Jost-Regular\';font-weight:400;margin:0;font-size:14px;letter-spacing:2px;text-transform:uppercase;color:#151515;margin-bottom:20px;']);

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'AtaGlance__Logo',
  componentId: 'sc-3tff4a-8'
})(['display:flex;width:100%;align-self:center;width:214px;height:auto;padding-top:50px;', ';'], _RollsRoyce2.default.min.medium(_templateObject6));

var removeEmptyValues = function removeEmptyValues(item) {
  if (item.value && item.value !== '') {
    return item;
  }
};


var AtaGlance = function AtaGlance(_ref) {
  var vehicle = _ref.vehicle,
      translations = _ref.translations,
      config = _ref.config,
      _ref$showApprovedLogo = _ref.showApprovedLogo,
      showApprovedLogo = _ref$showApprovedLogo === undefined ? true : _ref$showApprovedLogo;
  var _config$atAGlanceSect = config.atAGlanceSection,
      items = _config$atAGlanceSect.items,
      mainInfoLogo = _config$atAGlanceSect.mainInfoLogo;
  var atAGlanceLabel = translations.atAGlanceLabel,
      leftHandDrive = translations.leftHandDrive,
      rightHandDrive = translations.rightHandDrive,
      specificationFuelType = translations.specificationFuelType,
      specificationHandDrive = translations.specificationHandDrive,
      specificationModelYear = translations.specificationModelYear,
      specificationRegistrationYear = translations.specificationRegistrationYear,
      specificationTransmission = translations.specificationTransmission;
  var _vehicle$modelYear = vehicle.modelYear,
      modelYear = _vehicle$modelYear === undefined ? '' : _vehicle$modelYear,
      _vehicle$transmission = vehicle.transmissionType,
      transmissionType = _vehicle$transmission === undefined ? '' : _vehicle$transmission,
      _vehicle$registration = vehicle.registration,
      registration = _vehicle$registration === undefined ? '' : _vehicle$registration,
      _vehicle$handDrive = vehicle.handDrive,
      handDrive = _vehicle$handDrive === undefined ? '' : _vehicle$handDrive,
      _vehicle$approved = vehicle.approved,
      approved = _vehicle$approved === undefined ? null : _vehicle$approved,
      _vehicle$specificatio = vehicle.specification.fuelType,
      fuelType = _vehicle$specificatio === undefined ? '' : _vehicle$specificatio;


  var HandDrive = {
    L: leftHandDrive,
    R: rightHandDrive
  };

  var specs = [];

  var getItemImageUrl = function getItemImageUrl(key) {
    return (items.find(function (i) {
      return i.key === key;
    }) || {}).imageUrl;
  };

  specs.push({
    name: specificationModelYear,
    value: modelYear,
    imageUrl: getItemImageUrl('year')
  }, {
    name: specificationRegistrationYear,
    value: registration,
    imageUrl: getItemImageUrl('registration')
  }, {
    name: specificationTransmission,
    value: transmissionType,
    imageUrl: getItemImageUrl('transmission')
  }, {
    name: specificationFuelType,
    value: fuelType,
    imageUrl: getItemImageUrl('engine')
  }, {
    name: specificationHandDrive,
    value: HandDrive[handDrive],
    imageUrl: getItemImageUrl('drive')
  });

  return _react2.default.createElement(
    ColumnContainer,
    null,
    approved && showApprovedLogo && _react2.default.createElement(Logo, { src: mainInfoLogo, alt: 'Vehicle approved image' }),
    _react2.default.createElement(
      HeadingContainer,
      null,
      _react2.default.createElement(
        TitleHeader,
        null,
        atAGlanceLabel
      )
    ),
    _react2.default.createElement(
      RowContainer,
      null,
      specs.filter(removeEmptyValues).map(function (_ref2, index) {
        var name = _ref2.name,
            value = _ref2.value,
            imageUrl = _ref2.imageUrl;
        return _react2.default.createElement(
          Item,
          { key: 'specificationItem-' + index.toString() },
          _react2.default.createElement(ItemImage, { src: imageUrl, alt: name + ' icon image' }),
          _react2.default.createElement(
            ItemHeader,
            null,
            name
          ),
          _react2.default.createElement(
            ItemSubHeader,
            null,
            value
          )
        );
      })
    )
  );
};

exports.AtaGlance = AtaGlance;