'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n      gap: 100px;\n      padding: 100px 40px;\n    '], ['\n      gap: 100px;\n      padding: 100px 40px;\n    ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      font-size: 32px;\n      line-height: 42px;\n      letter-spacing: 2px;\n    '], ['\n      font-size: 32px;\n      line-height: 42px;\n      letter-spacing: 2px;\n    ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n      flex-direction: row;\n    '], ['\n      flex-direction: row;\n    ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n      flex-direction: column;\n      justify-content: center;\n    '], ['\n      flex-direction: column;\n      justify-content: center;\n    ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n      gap: 12px 20px;\n      grid-template-columns: repeat(2, 1fr);\n      margin-top: 40px;\n    '], ['\n      gap: 12px 20px;\n      grid-template-columns: repeat(2, 1fr);\n      margin-top: 40px;\n    ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n      gap: 12px 36px;\n      grid-template-columns: repeat(3, 1fr);\n    '], ['\n      gap: 12px 36px;\n      grid-template-columns: repeat(3, 1fr);\n    ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n      margin-top: 30px;\n    '], ['\n      margin-top: 30px;\n    ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n        margin: 30px 0;\n      '], ['\n        margin: 30px 0;\n      ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n      gap: 125px;\n    '], ['\n      gap: 125px;\n    ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n      flex-shrink: 0;\n      max-width: 556px;\n      width: 100%;\n    '], ['\n      flex-shrink: 0;\n      max-width: 556px;\n      width: 100%;\n    ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _Global = require('../../Global');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VehicleCollection = function VehicleCollection(_ref) {
  var backgroundGradient = _ref.backgroundGradient,
      body = _ref.body,
      features = _ref.features,
      images = _ref.images,
      title = _ref.title,
      _ref$textColor = _ref.textColor,
      textColor = _ref$textColor === undefined ? '#FFF' : _ref$textColor;

  var Section = _styledComponents2.default.section.withConfig({
    displayName: 'VehicleCollection__Section',
    componentId: 'sc-1bs1rk-0'
  })(['align-items:center;background:', ';color:', ';display:flex;flex-direction:column;gap:50px;padding:50px 20px;', ';'], backgroundGradient, textColor, _theme2.default.min.medium(_templateObject));

  var Heading = (0, _styledComponents2.default)(_Global.HeadingTwo).withConfig({
    displayName: 'VehicleCollection__Heading',
    componentId: 'sc-1bs1rk-1'
  })(['font-size:22px;line-height:29px;letter-spacing:2px;text-transform:uppercase;text-align:', ';', ';'], function (_ref2) {
    var _ref2$textAlign = _ref2.textAlign,
        textAlign = _ref2$textAlign === undefined ? 'left' : _ref2$textAlign;
    return textAlign;
  }, _theme2.default.min.medium(_templateObject2));

  var ImageContainer = _styledComponents2.default.div.withConfig({
    displayName: 'VehicleCollection__ImageContainer',
    componentId: 'sc-1bs1rk-2'
  })(['display:flex;flex-direction:column;gap:40px;', ';', ';'], _theme2.default.min.medium(_templateObject3), _theme2.default.min.large(_templateObject4));

  var Image = _styledComponents2.default.img.withConfig({
    displayName: 'VehicleCollection__Image',
    componentId: 'sc-1bs1rk-3'
  })(['display:block;width:100%;']);

  var FeatureList = _styledComponents2.default.ul.withConfig({
    displayName: 'VehicleCollection__FeatureList',
    componentId: 'sc-1bs1rk-4'
  })(['display:grid;gap:12px;grid-template-columns:repeat(1,1fr);list-style:none;margin:50px 0 0;padding:0;', ';', ';'], _theme2.default.min.medium(_templateObject5), _theme2.default.min.large(_templateObject6));

  var FeatureItem = _styledComponents2.default.li.withConfig({
    displayName: 'VehicleCollection__FeatureItem',
    componentId: 'sc-1bs1rk-5'
  })(['border-bottom:0.5px solid ', ';font-size:14px;letter-spacing:0.5px;line-height:20px;padding:10px 0 10px 10px;'], textColor);

  var Underline = _styledComponents2.default.div.withConfig({
    displayName: 'VehicleCollection__Underline',
    componentId: 'sc-1bs1rk-6'
  })(['background-color:', ';height:1px;margin-top:20px;width:50px;', ';'], textColor, _theme2.default.min.medium(_templateObject7));

  var StyledMarkdown = _styledComponents2.default.div.withConfig({
    displayName: 'VehicleCollection__StyledMarkdown',
    componentId: 'sc-1bs1rk-7'
  })(['margin-top:20px;white-space:pre-line;', ';p{font-size:14px;letter-spacing:0.5px;line-height:30px;margin:0;', ';}'], _theme2.default.min.medium(_templateObject7), _theme2.default.min.large(_templateObject8));

  var ContentContainer = _styledComponents2.default.div.withConfig({
    displayName: 'VehicleCollection__ContentContainer',
    componentId: 'sc-1bs1rk-8'
  })(['display:flex;flex-direction:column;gap:50px;max-width:1200px;', ';', ';'], _theme2.default.min.medium(_templateObject9), _theme2.default.min.large(_templateObject3));

  var TextContainer = _styledComponents2.default.div.withConfig({
    displayName: 'VehicleCollection__TextContainer',
    componentId: 'sc-1bs1rk-9'
  })(['', ';'], _theme2.default.min.large(_templateObject10));

  var FeaturesContainer = _styledComponents2.default.div.withConfig({
    displayName: 'VehicleCollection__FeaturesContainer',
    componentId: 'sc-1bs1rk-10'
  })(['max-width:1200px;width:100%;']);

  return _react2.default.createElement(
    Section,
    null,
    _react2.default.createElement(
      Heading,
      { styleOverride: function styleOverride() {
          return 'color: ' + textColor + ';';
        }, textAlign: 'center' },
      title
    ),
    _react2.default.createElement(
      ContentContainer,
      null,
      _react2.default.createElement(
        TextContainer,
        null,
        _react2.default.createElement(
          Heading,
          { styleOverride: function styleOverride() {
              return 'color: ' + textColor + ';';
            } },
          'Story',
          _react2.default.createElement(Underline, null)
        ),
        _react2.default.createElement(
          StyledMarkdown,
          null,
          _react2.default.createElement(
            _reactMarkdown2.default,
            null,
            body
          )
        )
      ),
      _react2.default.createElement(
        ImageContainer,
        null,
        images.map(function (_ref3) {
          var src = _ref3.src,
              alt = _ref3.alt;
          return _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(Image, { src: src, alt: alt })
          );
        })
      )
    ),
    _react2.default.createElement(
      FeaturesContainer,
      null,
      _react2.default.createElement(
        Heading,
        { styleOverride: function styleOverride() {
            return 'color: ' + textColor + ';';
          }, textAlign: 'center' },
        'Collection Features'
      ),
      _react2.default.createElement(
        FeatureList,
        null,
        features.map(function (feature) {
          return _react2.default.createElement(
            FeatureItem,
            null,
            feature
          );
        })
      )
    )
  );
};

exports.default = VehicleCollection;