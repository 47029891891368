'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ipInformation = require('../../../shared/localisation/ipInformation');

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

var _types = require('./types');

var _VehicleInformation = require('./VehicleInformation/');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__Wrapper',
  componentId: 'sc-hupo02-0'
})(['display:flex;flex-direction:column;']);

var VehicleDetailsPage = function VehicleDetailsPage(_ref) {
  var config = _ref.config,
      translations = _ref.translations,
      vehicle = _ref.vehicle,
      showApprovedLogo = _ref.showApprovedLogo;

  var countryCode = (0, _ipInformation.getIpCountryCode)();
  var userLocationGermany = countryCode === 'DE';

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(_VehicleInformation.AtaGlance, {
      vehicle: vehicle,
      translations: translations,
      config: config,
      showApprovedLogo: showApprovedLogo
    }),
    _react2.default.createElement(_VehicleInformation.VehicleFeatures, {
      vehicle: vehicle,
      translations: translations,
      config: config
    }),
    _react2.default.createElement(_VehicleInformation.AdditionalFeatures, {
      vehicle: vehicle,
      translations: translations,
      config: config
    }),
    _react2.default.createElement(_VehicleInformation.AdditionalComments, {
      vehicle: vehicle,
      translations: translations,
      config: config
    }),
    _react2.default.createElement(_VehicleInformation.Emissions, {
      vehicle: vehicle,
      translations: translations,
      config: config,
      userLocationGermany: userLocationGermany
    })
  );
};

exports.default = VehicleDetailsPage;