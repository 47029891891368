'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 300;\n    line-height: 16px;\n    letter-spacing: 0.5px;\n  '], ['\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 300;\n    line-height: 16px;\n    letter-spacing: 0.5px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useVehiclePrices2 = require('../../../../hooks/useVehiclePrices');

var _useVehiclePrices3 = _interopRequireDefault(_useVehiclePrices2);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleTypes = require('../../../../types/RollsRoyce/vehicleTypes');

var _GermanTaxDisclaimer = require('../../CustomCountryComponents/GermanTaxDisclaimer');

var _GermanTaxDisclaimer2 = _interopRequireDefault(_GermanTaxDisclaimer);

var _CarfaxLogo = require('../CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__Container',
  componentId: 'sc-4vq6il-0'
})(['display:flex;flex-direction:column;margin:0;']);

var HighlightsDataRow = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__HighlightsDataRow',
  componentId: 'sc-4vq6il-1'
})(['align-items:center;color:151515;display:flex;flex-direction:row;font-size:14px;font-weight:300;letter-spacing:0.5px;line-height:16px;padding:2px 0px 2px 0px;text-align:left;', ';', ';'], function (_ref) {
  var padded = _ref.padded;
  return padded && 'padding-bottom: 14px';
}, _theme2.default.max.medium(_templateObject));

var HighlightsLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__HighlightsLabel',
  componentId: 'sc-4vq6il-2'
})(['color:#151515;min-width:100px;']);

var HighlightsValue = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__HighlightsValue',
  componentId: 'sc-4vq6il-3'
})(['color:#151515;padding-left:5px;font-family:\'RivieraNights-Medium\',\'NotoSansKR-Medium\',Helvetica,Arial,-apple-system,sans-serif !important;', ';'], function (_ref2) {
  var valueBold = _ref2.valueBold;
  return valueBold && 'font-family: RivieraNights-Medium,Jost-Regular';
});

var InfoSection = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__InfoSection',
  componentId: 'sc-4vq6il-4'
})(['display:flex;flex-direction:column;']);

var NetPriceLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__NetPriceLabel',
  componentId: 'sc-4vq6il-5'
})(['font-size:12px;font-style:italic;']);

var OuterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__OuterContainer',
  componentId: 'sc-4vq6il-6'
})(['display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin:0;']);

var HighLight = function HighLight(_ref3) {
  var label = _ref3.label,
      value = _ref3.value,
      _ref3$padded = _ref3.padded,
      padded = _ref3$padded === undefined ? false : _ref3$padded,
      valueBold = _ref3.valueBold,
      children = _ref3.children;
  return _react2.default.createElement(
    HighlightsDataRow,
    { padded: padded },
    _react2.default.createElement(
      HighlightsLabel,
      null,
      label + ': '
    ),
    _react2.default.createElement(
      HighlightsValue,
      { valueBold: valueBold },
      value
    ),
    children
  );
};

var RowContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpVehiclePrice__RowContainer',
  componentId: 'sc-4vq6il-7'
})(['align-items:center;display:flex;flex-direction:row;gap:10px;white-space:nowrap;']);

var VdpVehiclePrice = function VdpVehiclePrice(_ref4) {
  var vehicleInfo = _ref4.vehicleInfo,
      translations = _ref4.translations,
      handleZeroPriceAsText = _ref4.handleZeroPriceAsText,
      config = _ref4.config,
      darkMode = _ref4.darkMode,
      isEnquiry = _ref4.isEnquiry;

  var _useVehiclePrices = (0, _useVehiclePrices3.default)(vehicleInfo, translations, handleZeroPriceAsText),
      grossPrice = _useVehiclePrices.grossPrice,
      netPrice = _useVehiclePrices.netPrice,
      taxPrice = _useVehiclePrices.taxPrice,
      taxNumber = _useVehiclePrices.taxNumber;

  var showRetailerPriceLabel = (!netPrice || taxNumber === 0) && grossPrice !== netPrice;

  var showSalesTaxDisclaimer = config.showSectionSalesTaxDisclaimer && !!vehicleInfo.sectionSalesTaxDisclaimer;

  return _react2.default.createElement(
    OuterContainer,
    null,
    _react2.default.createElement(
      Container,
      null,
      _react2.default.createElement(
        RowContainer,
        null,
        _react2.default.createElement(
          InfoSection,
          null,
          vehicleInfo.price.value !== 0 && netPrice && _react2.default.createElement(
            RowContainer,
            null,
            _react2.default.createElement(HighLight, {
              label: translations.netPriceLabelText,
              value: netPrice
            }),
            showSalesTaxDisclaimer && _react2.default.createElement(
              NetPriceLabel,
              null,
              translations.netPriceLabel
            )
          ),
          !!taxNumber && _react2.default.createElement(
            RowContainer,
            null,
            _react2.default.createElement(HighLight, { label: translations.tax, value: taxPrice })
          ),
          grossPrice && _react2.default.createElement(
            RowContainer,
            null,
            _react2.default.createElement(
              HighLight,
              {
                label: showRetailerPriceLabel ? translations.retailPriceLabelText : translations.grossPriceLabelText,
                value: grossPrice,
                valueBold: true
              },
              !isEnquiry && _react2.default.createElement(_GermanTaxDisclaimer2.default, {
                countryCode: vehicleInfo.retailerInformation.countryCode,
                showSalesTaxDisclaimer: showSalesTaxDisclaimer,
                salesTaxDisclaimer: translations.salesTaxDisclaimer,
                darkMode: darkMode,
                styleOverride: function styleOverride() {
                  return '\n                        margin-left: 2px;\n                      ';
                }
              })
            )
          )
        )
      )
    ),
    vehicleInfo.carfax && !darkMode && _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicleInfo.carfax })
  );
};

exports.default = VdpVehiclePrice;