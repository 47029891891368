'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n    padding: 0 5%;\n  '], ['\n  ', ';\n    padding: 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 10px 0;\n  '], ['\n    padding: 10px 0;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: center;\n  '], ['\n    justify-content: center;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n    margin-bottom: 20px;\n  '], ['\n    width: 100%;\n    margin-bottom: 20px;\n  ']);
/* eslint-disable react/prop-types */
/* eslint-env browser */


var _pathOr = require('ramda/src/pathOr');

var _pathOr2 = _interopRequireDefault(_pathOr);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _countryOptions = require('../helpers/countryOptions');

var _formTitleOptions = require('../helpers/formTitleOptions');

var _preferredTimeOptions = require('../helpers/preferredTimeOptions');

var _validation = require('../helpers/validation');

require('../modules/Enquiry/reducer');

var _ipInformation = require('../shared/localisation/ipInformation');

var _numbers = require('../shared/localisation/numbers');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _ConfirmationWindow = require('./ConfirmationWindow');

var _ConfirmationWindow2 = _interopRequireDefault(_ConfirmationWindow);

var _JLRKoreaSpecificConsent = require('./DataConsent/JLRKoreaSpecificConsent');

var _JLRKoreaSpecificConsent2 = _interopRequireDefault(_JLRKoreaSpecificConsent);

var _JLRMarketingAndDistributionConsent = require('./DataConsent/JLRMarketingAndDistributionConsent');

var _JLRMarketingAndDistributionConsent2 = _interopRequireDefault(_JLRMarketingAndDistributionConsent);

var _RRStyle = require('./DataConsent/RRStyle1');

var _RRStyle2 = _interopRequireDefault(_RRStyle);

var _DatePickerWrapper = require('./DatePickerWrapper');

var _DatePickerWrapper2 = _interopRequireDefault(_DatePickerWrapper);

var _FormFields = require('./FormFields');

var _Global = require('./Global');

var _LabelledCheckBox = require('./LabelledCheckBox');

var _LabelledCheckBox2 = _interopRequireDefault(_LabelledCheckBox);

var _PersonalDataConsent = require('./PersonalDataConsent');

var _PersonalDataConsent2 = _interopRequireDefault(_PersonalDataConsent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryForm__Container',
  componentId: 'sc-jenn63-0'
})(['display:flex;justify-content:center;']);

var EnquiryFormContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryForm__EnquiryFormContainer',
  componentId: 'sc-jenn63-1'
})(['', ';width:40%;', ';height:auto;margin:40px 0;'], _theme2.default.max.large(_templateObject, function (_ref) {
  var width = _ref.width;
  return 'width: ' + (width ? width + 'px' : '90%');
}), function (_ref2) {
  var width = _ref2.width;
  return 'width: ' + (width ? width + 'px' : '40%');
});

var DoubleRow = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryForm__DoubleRow',
  componentId: 'sc-jenn63-2'
})(['display:flex;justify-content:space-between;', ';'], _theme2.default.max.large(_templateObject2));

var MobileSingleRow = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryForm__MobileSingleRow',
  componentId: 'sc-jenn63-3'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject3));

var LineBreak = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryForm__LineBreak',
  componentId: 'sc-jenn63-4'
})(['border-bottom:1px solid #dedede;margin-bottom:30px;']);

var LineBreakExtended = (0, _styledComponents2.default)(LineBreak).withConfig({
  displayName: 'EnquiryForm__LineBreakExtended',
  componentId: 'sc-jenn63-5'
})(['margin:10px 0 20px 0;']);

var ActionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryForm__ActionsContainer',
  componentId: 'sc-jenn63-6'
})(['', ';display:flex;width:100%;justify-content:space-between;'], _theme2.default.max.large(_templateObject3));

var ActionButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryForm__ActionButtonWrapper',
  componentId: 'sc-jenn63-7'
})(['', ';'], _theme2.default.max.large(_templateObject4));

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryForm__Error',
  componentId: 'sc-jenn63-8'
})(['width:100%;display:flex;justify-content:flex-end;color:#9e1b32;margin-top:10px;', ';'], _theme2.default.max.large(_templateObject5));

var EnquiryFormDatePickerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryForm__EnquiryFormDatePickerWrapper',
  componentId: 'sc-jenn63-9'
})(['width:48%;', ';'], _theme2.default.max.large(_templateObject6));

var DatePickerLabel = _styledComponents2.default.label.withConfig({
  displayName: 'EnquiryForm__DatePickerLabel',
  componentId: 'sc-jenn63-10'
})(['text-align:left;margin-bottom:10px;display:block;']);

var BookingArrangementText = _styledComponents2.default.p.withConfig({
  displayName: 'EnquiryForm__BookingArrangementText',
  componentId: 'sc-jenn63-11'
})(['text-align:center;font-size:12px;']);

var EnquiryForm = function (_Component) {
  (0, _inherits3.default)(EnquiryForm, _Component);

  function EnquiryForm(props) {
    (0, _classCallCheck3.default)(this, EnquiryForm);

    var _this = (0, _possibleConstructorReturn3.default)(this, (EnquiryForm.__proto__ || (0, _getPrototypeOf2.default)(EnquiryForm)).call(this, props));

    Object.defineProperty(_this, 'onSelectChange', {
      enumerable: true,
      writable: true,
      value: function value(_ref3) {
        var keyValue = _ref3.keyValue,
            _value = _ref3.value,
            label = _ref3.label;

        var error = _this.validate(keyValue, { value: _value, label: label }, _this.props.translations, _this.props.applyRRValidations, _this.props.onDemandValidations);
        _this.setState(function (prevState) {
          var _ref4;

          return _ref4 = {}, (0, _defineProperty3.default)(_ref4, keyValue, { label: label, value: _value }), (0, _defineProperty3.default)(_ref4, 'errors', (0, _extends3.default)({}, prevState.errors, error)), _ref4;
        });
      }
    });
    Object.defineProperty(_this, 'preferenceToBinary', {
      enumerable: true,
      writable: true,
      value: function value(_value2) {
        return _value2 ? '1' : '0';
      }
    });
    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value3) {
        var error = _this.validate(formKey, _value3, _this.props.translations, _this.props.applyRRValidations, _this.props.onDemandValidations);

        _this.setState(function (prevState) {
          var _ref5;

          return _ref5 = {}, (0, _defineProperty3.default)(_ref5, formKey, _value3), (0, _defineProperty3.default)(_ref5, 'errors', (0, _extends3.default)({}, prevState.errors, error)), _ref5;
        });
      }
    });
    Object.defineProperty(_this, 'fullCommunicationConsentGiven', {
      enumerable: true,
      writable: true,
      value: function value(fullConsent) {
        return fullConsent === 'true' ? {
          phoneConsent: true,
          emailConsent: true,
          smsConsent: true,
          inCarConsent: true
        } : {
          phoneConsent: false,
          emailConsent: false,
          smsConsent: false,
          inCarConsent: false
        };
      }
    });
    Object.defineProperty(_this, 'onRadioSelect', {
      enumerable: true,
      writable: true,
      value: function value(event) {
        var _event$target = event.target,
            name = _event$target.name,
            value = _event$target.value;

        var error = _this.validate(name, value);
        _this.setState(function (prevState) {
          var _ref6;

          return _ref6 = {}, (0, _defineProperty3.default)(_ref6, name, value), (0, _defineProperty3.default)(_ref6, 'errors', (0, _extends3.default)({}, prevState.errors, error)), _ref6;
        });
      }
    });
    Object.defineProperty(_this, 'handleRadioChange', {
      enumerable: true,
      writable: true,
      value: function value(type) {
        return function (e) {
          var value = e.target.value;

          _this.setState((0, _defineProperty3.default)({}, type, value));
        };
      }
    });
    Object.defineProperty(_this, 'onRadioSelectMultiple', {
      enumerable: true,
      writable: true,
      value: function value(event) {
        var value = event.target.value;

        _this.setState(function () {
          return {
            dataCollectionConsent: value,
            promotionConsent: value,
            thirdPartyConsent: value,
            personalInfoHandling: value,
            consentToAll: value
          };
        });
      }
    });
    Object.defineProperty(_this, 'onSubmitMessage', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _ref8;

        var errors = (0, _entries2.default)(_this.state).map(function (entry) {
          return _this.validate(entry[0], entry[1], _this.props.translations, _this.props.applyRRValidations, _this.props.showJLRKoreaSpecificConsent, _this.props.onDemandValidations);
        }).filter(function (entry) {
          return entry !== undefined;
        }).reduce(function (acc, currVal) {
          return (0, _extends3.default)({}, acc, currVal);
        }, {});

        if (_this.props.applyRRValidations) {
          var _this$state = _this.state,
              _phoneConsent = _this$state.phoneConsent,
              _emailConsent = _this$state.emailConsent,
              _marketingConsent = _this$state.marketingConsent;

          if (_marketingConsent === 'yes' && !_phoneConsent && !_emailConsent) {
            errors = (0, _extends3.default)({}, errors, {
              checkBoxError: _this.props.translations.formValidationRequired
            });
          }
        }

        if (_this.props.showJLRKoreaSpecificConsent) {
          var _this$state2 = _this.state,
              _dataCollectionConsent = _this$state2.dataCollectionConsent,
              _promotionConsent = _this$state2.promotionConsent,
              _thirdPartyConsent = _this$state2.thirdPartyConsent,
              _personalInfoHandling = _this$state2.personalInfoHandling;

          if (_dataCollectionConsent === '0' || _promotionConsent === '0' || _thirdPartyConsent === '0' || _personalInfoHandling === '0') {
            errors = (0, _extends3.default)({}, errors, {
              dataCollectionConsent: _this.props.translations.serviceWarning,
              promotionConsent: _this.props.translations.serviceWarning,
              thirdPartyConsent: _this.props.translations.serviceWarning,
              personalInfoHandling: _this.props.translations.serviceWarning
            });
          }
        }

        if ((0, _values2.default)(errors).filter(function (error) {
          return error !== undefined;
        }).length > 0) {
          window.scroll(0, 0);
          _this.setState(function (prevState) {
            return {
              errors: (0, _extends3.default)({}, prevState.errors, errors)
            };
          });
          return;
        }

        var translateConsentFromTemplate = function translateConsentFromTemplate(template, linkText) {
          var consentText = template.replace('{PRIVACY_LINK}', linkText);
          return consentText;
        };

        var removeconsents = function removeconsents(_ref7) {
          var smsConsent = _ref7.smsConsent,
              emailConsent = _ref7.emailConsent,
              phoneConsent = _ref7.phoneConsent,
              marketingConsent = _ref7.marketingConsent,
              customisedServicesConsent = _ref7.customisedServicesConsent,
              inCarConsent = _ref7.inCarConsent,
              rest = (0, _objectWithoutProperties3.default)(_ref7, ['smsConsent', 'emailConsent', 'phoneConsent', 'marketingConsent', 'customisedServicesConsent', 'inCarConsent']);
          return rest;
        };

        var leadData = !_this.props.showUserCommunication && !_this.props.showRRCustomDataConsentSection ? removeconsents(_this.state) : _this.state;

        _this.props.submitMessage((0, _extends3.default)((0, _extends3.default)({}, _this.props.vehicle, leadData, {
          title: _this.state.title.value,
          preferredTime: _this.state.preferredTime.value,
          enquiryType: _this.state.enquiryType.value,
          country: _this.state.country.value,
          vehicleCurrent: { tradeInFlag: _this.state.tradeInFlag },
          selectedDate: _this.props.showBookingFields ? _this.state.selectedDate : null
        }, _this.props.showFullConsentSection && _this.fullCommunicationConsentGiven(_this.state.fullConsent), {
          consents: (0, _extends3.default)({}, _this.props.showJLRMarketingAndDistributionConsent ? {
            'legal consent': _this.preferenceToBinary(_this.state.marketingConsent)
          } : {}, _this.props.showJLRKoreaSpecificConsent ? (_ref8 = {}, (0, _defineProperty3.default)(_ref8, _this.props.translations.dataCollectionConsent, _this.state.dataCollectionConsent), (0, _defineProperty3.default)(_ref8, _this.props.translations.marketingConsent, _this.state.promotionConsent), (0, _defineProperty3.default)(_ref8, _this.props.translations.thirdPartyProvision, _this.state.thirdPartyConsent), (0, _defineProperty3.default)(_ref8, _this.props.translations.personalInfoHandling, _this.state.personalInfoHandling), _ref8) : {}, !_this.props.showUserCommunication && !_this.props.showRRCustomDataConsentSection && !_this.props.showJLRMarketingAndDistributionConsent ? {
            'Customised Services': _this.preferenceToBinary(_this.state.customisedServicesConsent),
            Marketing: _this.preferenceToBinary(_this.state.marketingConsent)
          } : {}, _this.props.sendConsentContent && {
            statement: translateConsentFromTemplate(_this.props.translations.dataConsentContent, _this.props.translations.privacyPolicyLinkText) + ' ' + _this.props.translations.dataConsentEmailLabel
          })
        })));
      }
    });
    Object.defineProperty(_this, 'formatVehicleOfInterest', {
      enumerable: true,
      writable: true,
      value: function value(vehicle, keys) {
        return (0, _pathOr2.default)('', keys, vehicle) !== '' ? (0, _pathOr2.default)('', keys, vehicle) + ' | ' : '';
      }
    });
    Object.defineProperty(_this, 'validate', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value4) {
        var _this$props = _this.props,
            applyRRValidations = _this$props.applyRRValidations,
            onDemandValidations = _this$props.onDemandValidations,
            translations = _this$props.translations,
            showUserCommunication = _this$props.showUserCommunication,
            showJLRMarketingAndDistributionConsent = _this$props.showJLRMarketingAndDistributionConsent,
            postCodeRequired = _this$props.postCodeRequired,
            phoneNumberRequired = _this$props.phoneNumberRequired;

        var excludeRRKeys = ['address1', 'city', 'county', 'country', 'postCode', 'marketingConsent', 'comments'];
        var excludeRRWhenOnDemandKeys = ['city', 'county', 'country'];
        var excludeNewPrefKeys = ['marketingConsent', 'customisedServicesConsent'];
        var excludeLamboKeys = ['vehicleBrand', 'vehicleModel'];
        var exludePostCode = function exludePostCode(nonValidatedKeys) {
          return nonValidatedKeys.filter(function (key) {
            return key !== 'postCode';
          });
        };

        var excludePhoneNumber = function excludePhoneNumber(nonValidatedKeys) {
          return nonValidatedKeys.filter(function (key) {
            return key !== 'phone';
          });
        };

        var nonValidatedKeys = ['enquiryType', 'vehicleOfInterest', 'phoneConsent', 'smsConsent', 'emailConsent', 'inCarConsent', 'fullConsent', 'tradeInFlag', 'errors', 'calendarFocused', 'selectedDate', 'preferredTime', 'address2', 'tradeInVisible', 'dataCollectionConsent', 'promotionConsent', 'thirdPartyConsent', 'personalInfoHandling', 'consentToAll', 'marketingConsent', 'phone', 'dataConsentContent'];
        var actualValidationKeys = [].concat(nonValidatedKeys);

        if (!applyRRValidations) {
          actualValidationKeys = onDemandValidations ? [].concat((0, _toConsumableArray3.default)(actualValidationKeys), excludeRRWhenOnDemandKeys) : [].concat((0, _toConsumableArray3.default)(actualValidationKeys), excludeRRKeys);
        }

        if (applyRRValidations) {
          actualValidationKeys = [].concat((0, _toConsumableArray3.default)(actualValidationKeys), excludeRRKeys, ['customisedServicesConsent']);
        }
        if (!_this.state.tradeInVisible) {
          actualValidationKeys = [].concat((0, _toConsumableArray3.default)(actualValidationKeys), excludeLamboKeys);
        }

        if (showUserCommunication || showJLRMarketingAndDistributionConsent) {
          actualValidationKeys = [].concat((0, _toConsumableArray3.default)(actualValidationKeys), excludeNewPrefKeys);
        }
        if (postCodeRequired || _this.showZipCode.includes(_this.countryCode)) {
          actualValidationKeys = exludePostCode(actualValidationKeys);
        }
        if (phoneNumberRequired) {
          actualValidationKeys = excludePhoneNumber(actualValidationKeys);
        }
        if (actualValidationKeys.includes(formKey)) return undefined;

        var rollsRoyceValidations = void 0;
        if (applyRRValidations) {
          rollsRoyceValidations = {
            city: _validation.required,
            address1: _validation.required,
            county: _validation.required,
            country: _validation.required,
            postCode: _validation.required
          };
        }
        var lamborghiniValidations = void 0;
        if (_this.state.tradeInVisible) {
          lamborghiniValidations = {
            vehicleBrand: _validation.required,
            vehicleModel: _validation.required
          };
        }

        var onDemandRequired = void 0;
        if (onDemandValidations) {
          onDemandRequired = {
            address1: _validation.required,
            postCode: _validation.required
          };
        }

        var validations = {
          title: _validation.required,
          firstName: _validation.required,
          lastName: _validation.required,
          email: _validation.validEmail,
          comments: _validation.required,
          marketingConsent: _validation.required,
          customisedServicesConsent: _validation.required
        };
        var actualValidations = (0, _extends3.default)({}, validations);
        if (applyRRValidations) {
          actualValidations = (0, _extends3.default)({}, actualValidations, rollsRoyceValidations);
        }

        if (_this.state.tradeInVisible) {
          actualValidations = (0, _extends3.default)({}, actualValidations, lamborghiniValidations);
        }

        if (onDemandValidations) {
          actualValidations = (0, _extends3.default)({}, actualValidations, onDemandRequired);
        }
        if (postCodeRequired || _this.showZipCode.includes(_this.countryCode)) actualValidations = (0, _extends3.default)({}, actualValidations, {
          postCode: _validation.required
        });
        if (phoneNumberRequired) {
          actualValidations = (0, _extends3.default)({}, actualValidations, {
            phone: _validation.validPhoneNumber
          });
        }
        if (formKey === 'title' || formKey === 'country' && applyRRValidations) {
          return (0, _defineProperty3.default)({}, formKey, actualValidations[formKey](_value4.value, translations));
        }
        return (0, _defineProperty3.default)({}, formKey, actualValidations[formKey](_value4, translations));
      }
    });
    Object.defineProperty(_this, 'toggleEnquiryCalendar', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.setState(function (prevState) {
          return {
            calendarFocused: !prevState.calendarFocused
          };
        });
      }
    });
    Object.defineProperty(_this, 'closeEnquiryCalendar', {
      enumerable: true,
      writable: true,
      value: function value(e) {
        if (!_this.props.showBookingFields) return;
        if (!e.target.classList[0]) return;
        if (!e.target.classList[0].includes('DatePickerWrapper')) {
          _this.setState({
            calendarFocused: false
          });
        }
      }
    });
    Object.defineProperty(_this, 'handleOnDateSelected', {
      enumerable: true,
      writable: true,
      value: function value(date, selectedDate) {
        if (date === 'date') {
          _this.setState({
            selectedDate: selectedDate
          });
        }
      }
    });
    Object.defineProperty(_this, 'getVehicleOfInterest', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (_this.props.vehicle === null) {
          return '';
        }

        var vehicleOfInterest = _this.props.rrVehicleOfInterest ? _this.props.vehicle && '' + _this.formatVehicleOfInterest(_this.props.vehicle, ['name']) + ('' + _this.formatVehicleOfInterest(_this.props.vehicle, ['variant'])) + ('' + (_this.props.vehicle.specification ? _this.formatVehicleOfInterest(_this.props.vehicle, ['specification', 'exterior']) : _this.formatVehicleOfInterest(_this.props.vehicle, ['exterior']))) + ('' + (_this.props.vehicle.price.value !== 0 ? (0, _numbers.localiseCurrency)(_this.props.vehicle.price.value, _this.props.vehicle.locale, _this.props.vehicle.price.currency, 2) + ' | ' : '')) + ('' + _this.props.vehicle.vin) : _this.props.vehicle && (_this.props.hideYear ? _this.props.vehicle.description : _this.props.vehicle.registration + ' ' + _this.props.vehicle.description) || '';

        return vehicleOfInterest;
      }
    });
    Object.defineProperty(_this, 'countryCode', {
      enumerable: true,
      writable: true,
      value: (0, _ipInformation.getIpCountryCode)() || ''
    });


    _this.state = {
      title: {
        label: props.userDetails.title || props.translations.formTitleSelect,
        value: props.userDetails.title || ''
      },
      preferredTime: {
        label: props.translations.formTitleSelect,
        value: props.translations.formPreferredTimeEmpty || ''
      },
      enquiryType: props.enquiryTypeLabel && props.enquiryTypeValue ? {
        label: props.enquiryTypeLabel,
        value: props.enquiryTypeValue
      } : {
        label: props.translations.initialEnquiryTypeLabel || props.translations.generalEnquiry || 'General Enquiry',
        value: props.enquiryTypes && props.enquiryTypes.default || 'general_enquiry'
      },
      firstName: props.userDetails.firstName,
      lastName: props.userDetails.lastName,
      email: props.userDetails.email,
      phone: props.userDetails.phoneNumber,
      postCode: '',
      comments: '',
      phoneConsent: false,
      emailConsent: false,
      smsConsent: false,
      inCarConsent: false,
      fullConsent: false,
      marketingConsent: false,
      customisedServicesConsent: '',
      errors: {},
      address1: '',
      address2: '',
      city: '',
      county: '',
      country: {
        label: props.userDetails.country || props.translations.formCountrySelect,
        value: props.userDetails.country || ''
      },
      tradeInVisible: false,
      tradeInFlag: false,
      vehicleBrand: '',
      vehicleModel: '',
      selectedDate: null,
      // Korea Specific consents
      consentToAll: null,
      dataCollectionConsent: '0',
      promotionConsent: '0',
      thirdPartyConsent: '0',
      personalInfoHandling: '0'
    };
    _this.showZipCode = ['MX', 'CA', 'US'];
    return _this;
  }

  (0, _createClass3.default)(EnquiryForm, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          vehicle = _props.vehicle,
          history = _props.history,
          translations = _props.translations,
          error = _props.error,
          submissionStatus = _props.submissionStatus,
          preview = _props.preview,
          globalStyling = _props.globalStyling,
          styledDisabledInput = _props.styledDisabledInput,
          _props$enquiryTypeDro = _props.enquiryTypeDropdownVisible,
          enquiryTypeDropdownVisible = _props$enquiryTypeDro === undefined ? true : _props$enquiryTypeDro,
          _props$showPostCodeFi = _props.showPostCodeField,
          showPostCodeField = _props$showPostCodeFi === undefined ? false : _props$showPostCodeFi,
          _props$showAddressFie = _props.showAddressFields,
          showAddressFields = _props$showAddressFie === undefined ? false : _props$showAddressFie,
          _props$showVehicleOfI = _props.showVehicleOfInterest,
          showVehicleOfInterest = _props$showVehicleOfI === undefined ? false : _props$showVehicleOfI,
          _props$showVehicleOfI2 = _props.showVehicleOfInterestSingle,
          showVehicleOfInterestSingle = _props$showVehicleOfI2 === undefined ? true : _props$showVehicleOfI2,
          vehicleOfInterestPositionTop = _props.vehicleOfInterestPositionTop,
          _props$showBookingFie = _props.showBookingFields,
          showBookingFields = _props$showBookingFie === undefined ? false : _props$showBookingFie,
          _props$showDualCounty = _props.showDualCountyPostCodeRow,
          showDualCountyPostCodeRow = _props$showDualCounty === undefined ? false : _props$showDualCounty,
          _props$showAddressRow = _props.showAddressRows,
          showAddressRows = _props$showAddressRow === undefined ? false : _props$showAddressRow,
          _props$useTimeOfDay = _props.useTimeOfDay,
          useTimeOfDay = _props$useTimeOfDay === undefined ? false : _props$useTimeOfDay,
          showFullConsentSection = _props.showFullConsentSection,
          enquiryTypes = _props.enquiryTypes,
          calendarStyles = _props.calendarStyles,
          marketingLink = _props.marketingLink,
          showUserCommunication = _props.showUserCommunication,
          sendConsentContent = _props.sendConsentContent,
          formWidth = _props.formWidth,
          dataConsentFont = _props.dataConsentFont,
          showRRCustomDataConsentSection = _props.showRRCustomDataConsentSection,
          showJLRMarketingAndDistributionConsent = _props.showJLRMarketingAndDistributionConsent,
          showJLRKoreaSpecificConsent = _props.showJLRKoreaSpecificConsent,
          showTradeInFields = _props.showTradeInFields,
          phoneNumberRequired = _props.phoneNumberRequired,
          locale = _props.locale,
          _props$enquiryFormSty = _props.enquiryFormStyles,
          enquiryFormStyles = _props$enquiryFormSty === undefined ? {} : _props$enquiryFormSty,
          checkboxLabelStyles = _props.checkboxLabelStyles,
          consentRadioOptions = _props.consentRadioOptions,
          _props$commentsRequir = _props.commentsRequired,
          commentsRequired = _props$commentsRequir === undefined ? true : _props$commentsRequir,
          _props$commentsSingle = _props.commentsSingleLine,
          commentsSingleLine = _props$commentsSingle === undefined ? false : _props$commentsSingle,
          config = _props.config;


      var firstSelectable = new Date();

      var _state = this.state,
          enquiryType = _state.enquiryType,
          title = _state.title,
          firstName = _state.firstName,
          lastName = _state.lastName,
          email = _state.email,
          phone = _state.phone,
          postCode = _state.postCode,
          comments = _state.comments,
          phoneConsent = _state.phoneConsent,
          emailConsent = _state.emailConsent,
          smsConsent = _state.smsConsent,
          inCarConsent = _state.inCarConsent,
          preferredTime = _state.preferredTime,
          address1 = _state.address1,
          address2 = _state.address2,
          county = _state.county,
          city = _state.city,
          country = _state.country,
          marketingConsent = _state.marketingConsent,
          customisedServicesConsent = _state.customisedServicesConsent,
          tradeInFlag = _state.tradeInFlag,
          dataCollectionConsent = _state.dataCollectionConsent,
          promotionConsent = _state.promotionConsent,
          thirdPartyConsent = _state.thirdPartyConsent,
          personalInfoHandling = _state.personalInfoHandling,
          consentToAll = _state.consentToAll;


      var vehicleOfInterest = this.getVehicleOfInterest();

      var _ref11 = enquiryTypes && enquiryTypes.options ? enquiryTypes.options : '',
          _ref11$generalEnquiry = _ref11.generalEnquiryValue,
          generalEnquiryValue = _ref11$generalEnquiry === undefined ? 'general_enquiry' : _ref11$generalEnquiry,
          reserveValue = _ref11.reserveValue,
          testDriveValue = _ref11.testDriveValue,
          financeValue = _ref11.financeValue,
          onDemandValue = _ref11.onDemandValue;

      var generalEnquiryLabel = translations.generalEnquiry,
          reserveEnquiryLabel = translations.reserve,
          testDriveEnquiryLabel = translations.testDrive,
          financeEnquiryLabel = translations.finance,
          onDemandEnquiryLabel = translations.onDemand;


      var enquiryTypeOptions = function enquiryTypeOptions() {
        return [{
          label: generalEnquiryLabel,
          value: generalEnquiryValue
        }, {
          label: reserveEnquiryLabel,
          value: reserveValue
        }, {
          label: testDriveEnquiryLabel,
          value: testDriveValue
        }, {
          label: financeEnquiryLabel,
          value: financeValue
        }, {
          label: onDemandEnquiryLabel,
          value: onDemandValue
        }];
      };

      var morning = translations.morning,
          afternoon = translations.afternoon,
          evening = translations.evening;


      var preferredTimeOptionsAll = function preferredTimeOptionsAll() {
        var label = _this2.props.translations.formTitleSelect;
        var timeOptions = useTimeOfDay ? [{
          label: morning,
          value: morning
        }, {
          label: afternoon,
          value: afternoon
        }, {
          label: evening,
          value: evening
        }] : (0, _preferredTimeOptions.preferredTimeOptions)();
        var timeOptionsFromConfig = _this2.props.config.timeOptions;

        return [{
          label: label,
          value: ''
        }].concat((0, _toConsumableArray3.default)(timeOptionsFromConfig && timeOptionsFromConfig.length > 0 ? timeOptionsFromConfig : timeOptions));
      };

      var enquiryTypesFiltered = function enquiryTypesFiltered() {
        var options = enquiryTypeOptions();
        var filteredOptions = options.filter(function (option) {
          return option.label && option.value;
        });
        return filteredOptions;
      };

      var enquiryTypeLength = function enquiryTypeLength() {
        var options = enquiryTypesFiltered();
        return options.length;
      };

      var hasError = function hasError(field) {
        return _this2.state.errors[field] && _this2.state.errors[field][0];
      };
      var fnIfNotPreview = function fnIfNotPreview(fn) {
        return preview ? function () {} : fn;
      };

      var showPersonalDataConsent = !showRRCustomDataConsentSection && !showJLRMarketingAndDistributionConsent && !showJLRKoreaSpecificConsent;

      return submissionStatus === 'Successful' ? _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(_ConfirmationWindow2.default, {
          translations: translations,
          globalStyling: globalStyling,
          circleTickColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
          onButtonClick: fnIfNotPreview(history.goBack),
          config: config
        })
      ) : _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(
          EnquiryFormContainer,
          {
            width: formWidth,
            globalStyling: globalStyling,
            onClick: this.closeEnquiryCalendar
          },
          showVehicleOfInterestSingle && vehicleOfInterestPositionTop && _react2.default.createElement(_FormFields.InputField, {
            type: 'text',
            label: translations.vehicleOfInterestLabel,
            value: vehicleOfInterest,
            onChange: fnIfNotPreview(this.onInputChange),
            keyValue: 'vehicleOfInterest',
            disabled: !!vehicle,
            truncateChars: true,
            globalStyling: globalStyling,
            styledDisabledInput: styledDisabledInput
          }),
          (enquiryTypeDropdownVisible || enquiryTypeLength() > 1) && _react2.default.createElement(_FormFields.SelectField, {
            keyValue: 'enquiryType',
            value: enquiryType.value,
            onChange: fnIfNotPreview(this.onSelectChange),
            label: translations.enquiryTypeLabel,
            options: enquiryTypeOptions(),
            disabled: enquiryTypeLength() === 1,
            enquiryTypes: enquiryTypes,
            doubleRow: true,
            required: true,
            globalStyling: globalStyling
          }),
          _react2.default.createElement(_FormFields.SelectField, {
            type: 'text',
            keyValue: 'title',
            value: title.value,
            onChange: fnIfNotPreview(this.onSelectChange),
            label: translations.titleLabel,
            options: (0, _formTitleOptions.titleOptions)(translations),
            error: hasError('title'),
            doubleRow: true,
            required: true,
            globalStyling: globalStyling
          }),
          _react2.default.createElement(
            DoubleRow,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.firstNameLabel,
              value: firstName,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'firstName',
              error: hasError('firstName'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.lastNameLabel,
              value: lastName,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'lastName',
              error: hasError('lastName'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            })
          ),
          _react2.default.createElement(
            MobileSingleRow,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.firstNameLabel,
              value: firstName,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'firstName',
              error: hasError('firstName'),
              required: true,
              globalStyling: globalStyling
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.lastNameLabel,
              value: lastName,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'lastName',
              error: hasError('lastName'),
              required: true,
              globalStyling: globalStyling
            })
          ),
          showAddressRows && _react2.default.createElement(
            DoubleRow,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.address1Label,
              value: address1,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'address1',
              error: hasError('address1'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.cityLabel,
              value: city,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'city',
              error: hasError('city'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            })
          ),
          showAddressRows && _react2.default.createElement(
            MobileSingleRow,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.address1Label,
              value: address1,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'address1',
              error: hasError('address1'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.cityLabel,
              value: city,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'city',
              error: hasError('city'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            })
          ),
          showDualCountyPostCodeRow && _react2.default.createElement(
            DoubleRow,
            null,
            _react2.default.createElement(
              'div',
              {
                style: {
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '48%'
                }
              },
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.countyLabel,
                value: county,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'county',
                error: hasError('county'),
                doubleRow: true,
                required: true,
                globalStyling: globalStyling
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.postCodeLabel,
                value: postCode,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'postCode',
                error: hasError('postCode'),
                doubleRow: true,
                required: true,
                globalStyling: globalStyling
              })
            ),
            _react2.default.createElement(_FormFields.SelectField, {
              type: 'text',
              keyValue: 'country',
              value: country.value,
              onChange: fnIfNotPreview(this.onSelectChange),
              label: translations.countryLabel,
              options: (0, _countryOptions.countryOptions)(translations),
              error: hasError('country'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            })
          ),
          showDualCountyPostCodeRow && _react2.default.createElement(
            MobileSingleRow,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.countyLabel,
              value: county,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'county',
              error: hasError('county'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.postCodeLabel,
              value: postCode,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'postCode',
              error: hasError('postCode'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            }),
            _react2.default.createElement(_FormFields.SelectField, {
              type: 'text',
              keyValue: 'country',
              value: country.value,
              onChange: fnIfNotPreview(this.onSelectChange),
              label: translations.countryLabel,
              options: (0, _countryOptions.countryOptions)(translations),
              error: hasError('country'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            })
          ),
          _react2.default.createElement(
            DoubleRow,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.emailLabel,
              value: email,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'email',
              error: hasError('email'),
              doubleRow: true,
              required: true,
              globalStyling: globalStyling
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.phoneLabel,
              value: phone,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'phone',
              error: hasError('phone'),
              doubleRow: true,
              globalStyling: globalStyling,
              required: phoneNumberRequired,
              onKeyPress: function onKeyPress(event) {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }
            })
          ),
          this.showZipCode.includes(this.countryCode) && _react2.default.createElement(_FormFields.InputField, {
            type: 'text',
            label: translations.zipCodeLabel,
            value: postCode,
            onChange: fnIfNotPreview(this.onInputChange),
            keyValue: 'postCode',
            error: hasError('postCode'),
            required: true,
            maxLength: '10',
            globalStyling: globalStyling
          }),
          _react2.default.createElement(
            MobileSingleRow,
            null,
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.emailLabel,
              value: email,
              onChange: fnIfNotPreview(this.onInputChange),
              error: hasError('email'),
              keyValue: 'email',
              required: true,
              globalStyling: globalStyling
            }),
            _react2.default.createElement(_FormFields.InputField, {
              type: 'text',
              label: translations.phoneLabel,
              value: phone,
              onChange: fnIfNotPreview(this.onInputChange),
              keyValue: 'phone',
              error: hasError('phone'),
              globalStyling: globalStyling,
              required: phoneNumberRequired,
              onKeyPress: function onKeyPress(event) {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }
            })
          ),
          showAddressFields && _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(
              DoubleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.address1Label,
                value: address1,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'address1',
                doubleRow: true,
                required: true,
                globalStyling: globalStyling,
                error: hasError('address1')
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.address2Label,
                value: address2,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'address2',
                doubleRow: true,
                globalStyling: globalStyling
              })
            ),
            _react2.default.createElement(
              DoubleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.postCode,
                value: postCode,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'postCode',
                doubleRow: true,
                globalStyling: globalStyling,
                required: true,
                error: hasError('postCode')
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.cityLabel,
                value: city,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'city',
                error: hasError('city'),
                doubleRow: true,
                globalStyling: globalStyling
              })
            ),
            _react2.default.createElement(
              MobileSingleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.address1Label,
                value: address1,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'address1',
                doubleRow: true,
                required: true,
                globalStyling: globalStyling,
                error: hasError('address1')
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.address2Label,
                value: address2,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'address2',
                doubleRow: true,
                globalStyling: globalStyling
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.postCode,
                value: postCode,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'postCode',
                doubleRow: true,
                globalStyling: globalStyling,
                required: true,
                error: hasError('postCode')
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.cityLabel,
                value: city,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'city',
                error: hasError('city'),
                doubleRow: true,
                globalStyling: globalStyling
              })
            )
          ),
          showPostCodeField && showVehicleOfInterest && _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(
              DoubleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.postCode,
                value: postCode,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'postCode',
                doubleRow: true,
                globalStyling: globalStyling,
                required: true,
                error: hasError('postCode')
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.vehicleOfInterestLabel,
                value: vehicleOfInterest,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'vehicleOfInterest',
                disabled: !!vehicle,
                doubleRow: true,
                globalStyling: globalStyling
              })
            ),
            _react2.default.createElement(
              MobileSingleRow,
              null,
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.postCode,
                value: postCode,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'postCode',
                doubleRow: true,
                globalStyling: globalStyling,
                required: true,
                error: hasError('postCode')
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.vehicleOfInterestLabel,
                value: vehicleOfInterest,
                onChange: fnIfNotPreview(this.onInputChange),
                keyValue: 'vehicleOfInterest',
                disabled: !!vehicle,
                doubleRow: true,
                globalStyling: globalStyling
              })
            )
          ),
          showVehicleOfInterestSingle && !vehicleOfInterestPositionTop && _react2.default.createElement(_FormFields.InputField, {
            type: 'text',
            label: translations.vehicleOfInterestLabel,
            value: vehicleOfInterest,
            onChange: fnIfNotPreview(this.onInputChange),
            keyValue: 'vehicleOfInterest',
            disabled: !!vehicle,
            truncateChars: true,
            globalStyling: globalStyling
          }),
          showBookingFields && _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(LineBreakExtended, null),
            _react2.default.createElement(
              DoubleRow,
              null,
              _react2.default.createElement(
                EnquiryFormDatePickerWrapper,
                null,
                _react2.default.createElement(
                  DatePickerLabel,
                  null,
                  translations.datePickerLabel
                ),
                _react2.default.createElement(_DatePickerWrapper2.default, {
                  open: this.state.calendarFocused,
                  date: this.state.selectedDate || firstSelectable,
                  selected: this.state.selectedDate,
                  value: this.state.selectedDate,
                  onSelectDate: this.handleOnDateSelected,
                  toggleCalendar: this.toggleEnquiryCalendar,
                  globalStyling: globalStyling,
                  doubleRow: true,
                  calendarStyles: calendarStyles,
                  translations: translations,
                  locale: locale,
                  selectableDaysOfWeek: this.props.config.selectableDaysOfWeek
                })
              ),
              _react2.default.createElement(_FormFields.SelectField, {
                type: 'text',
                keyValue: 'preferredTime',
                value: preferredTime.value,
                onChange: fnIfNotPreview(this.onSelectChange),
                label: translations.preferredTimeLabel,
                options: preferredTimeOptionsAll(),
                doubleRow: true,
                calendarStyles: calendarStyles,
                translations: translations,
                globalStyling: globalStyling
              })
            ),
            _react2.default.createElement(
              MobileSingleRow,
              null,
              _react2.default.createElement(
                EnquiryFormDatePickerWrapper,
                null,
                _react2.default.createElement(
                  DatePickerLabel,
                  null,
                  translations.datePickerLabel
                ),
                _react2.default.createElement(_DatePickerWrapper2.default, {
                  open: this.state.calendarFocused,
                  date: this.state.selectedDate || firstSelectable,
                  selected: this.state.selectedDate,
                  value: this.state.selectedDate,
                  onSelectDate: this.handleOnDateSelected,
                  toggleCalendar: this.toggleEnquiryCalendar,
                  globalStyling: globalStyling,
                  doubleRow: true,
                  calendarStyles: calendarStyles,
                  translations: translations,
                  locale: locale,
                  mobileWidth: 90,
                  selectableDaysOfWeek: this.props.config.selectableDaysOfWeek
                })
              ),
              _react2.default.createElement(_FormFields.SelectField, {
                type: 'text',
                keyValue: 'preferredTime',
                value: preferredTime.value,
                onChange: fnIfNotPreview(this.onSelectChange),
                label: translations.preferredTimeLabel,
                options: preferredTimeOptionsAll(),
                doubleRow: true,
                calendarStyles: calendarStyles,
                translations: translations,
                globalStyling: globalStyling
              })
            ),
            _react2.default.createElement(
              BookingArrangementText,
              null,
              translations.bookingArrangementText
            )
          ),
          showTradeInFields && _react2.default.createElement(
            _react.Fragment,
            null,
            _react2.default.createElement(
              DoubleRow,
              { style: { marginBottom: '16px' } },
              _react2.default.createElement(_LabelledCheckBox2.default, {
                onClick: function onClick() {
                  _this2.setState(function () {
                    return { tradeInFlag: !tradeInFlag };
                  });
                },
                checked: tradeInFlag,
                keyValue: 'tradeInFlag',
                label: translations.requestTradeInLabel,
                onColour: '#05141F',
                globalStyling: globalStyling
              })
            )
          ),
          showTradeInFields && _react2.default.createElement(
            'div',
            { style: { marginBottom: '16px' } },
            _react2.default.createElement(
              MobileSingleRow,
              null,
              _react2.default.createElement(_LabelledCheckBox2.default, {
                onClick: function onClick() {
                  _this2.setState(function () {
                    return { tradeInFlag: !tradeInFlag };
                  });
                },
                checked: tradeInFlag,
                keyValue: 'tradeInFlag',
                label: translations.requestTradeInLabel,
                onColour: '#05141F',
                globalStyling: globalStyling
              })
            )
          ),
          _react2.default.createElement(_FormFields.TextAreaField, {
            type: 'text',
            label: translations.commentsLabel,
            value: comments,
            onChange: fnIfNotPreview(this.onInputChange),
            keyValue: 'comments',
            error: hasError('comments'),
            required: commentsRequired,
            commentsSingleLine: commentsSingleLine,
            globalStyling: globalStyling
          }),
          _react2.default.createElement(
            'div',
            {
              style: { fontSize: '13px', color: '#444444', marginBottom: '30px' }
            },
            translations.asteriskFormRules
          ),
          _react2.default.createElement(LineBreak, null),
          showRRCustomDataConsentSection && _react2.default.createElement(_RRStyle2.default, {
            translations: translations,
            onCheckBoxClick: fnIfNotPreview(this.onInputChange),
            onRadioSelect: this.onRadioSelect,
            phoneChecked: phoneConsent,
            phoneKey: 'phoneConsent',
            emailChecked: emailConsent,
            emailKey: 'emailConsent',
            smsChecked: smsConsent,
            smsKey: 'smsConsent',
            inCarChecked: inCarConsent,
            inCarKey: 'inCarConsent',
            marketingChecked: marketingConsent,
            marketingKey: 'marketingConsent',
            onColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
            linkColour: globalStyling.colours.primaryBrandColour,
            marketingLink: marketingLink,
            showUserCommunication: showUserCommunication,
            globalStyling: globalStyling,
            dataConsentFont: dataConsentFont,
            checkBoxError: hasError('checkBoxError')
          }),
          showJLRMarketingAndDistributionConsent && _react2.default.createElement(_JLRMarketingAndDistributionConsent2.default, {
            translations: translations,
            onCheckBoxClick: fnIfNotPreview(this.onInputChange),
            onRadioSelect: this.onRadioSelect,
            marketingChecked: marketingConsent,
            marketingKey: 'marketingConsent',
            onColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
            linkColour: globalStyling.colours.primaryBrandColour,
            marketingLink: marketingLink,
            showUserCommunication: showUserCommunication,
            globalStyling: globalStyling,
            dataConsentFont: dataConsentFont,
            checkBoxError: hasError('checkBoxError'),
            checkboxLabelStyles: checkboxLabelStyles && checkboxLabelStyles.fontSize,
            enquiryFormStyles: enquiryFormStyles
          }),
          showJLRKoreaSpecificConsent && _react2.default.createElement(_JLRKoreaSpecificConsent2.default, {
            translations: translations,
            onRadioSelect: this.onRadioSelect,
            onRadioSelectMultiple: this.onRadioSelectMultiple,
            consentToAll: consentToAll,
            consentToAllKey: 'consentToAll',
            dataCollectionConsent: dataCollectionConsent,
            dataCollectionConsentKey: 'dataCollectionConsent',
            dataCollectionConsentError: hasError('dataCollectionConsent'),
            promotionConsent: promotionConsent,
            promotionConsentKey: 'promotionConsent',
            promotionConsentError: hasError('promotionConsent'),
            thirdPartyConsent: thirdPartyConsent,
            thirdPartyConsentKey: 'thirdPartyConsent',
            thirdPartyConsentError: hasError('thirdPartyConsent'),
            personalInfoHandling: personalInfoHandling,
            personalInfoHandlingKey: 'personalInfoHandling',
            personalInfoHandlingError: hasError('personalInfoHandling'),
            onColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
            linkColour: globalStyling.colours.primaryBrandColour,
            marketingLink: marketingLink,
            showUserCommunication: showUserCommunication,
            globalStyling: globalStyling,
            labelFontSize: checkboxLabelStyles && checkboxLabelStyles.fontSize,
            enquiryFormStyles: enquiryFormStyles
          }),
          showPersonalDataConsent && _react2.default.createElement(_PersonalDataConsent2.default, {
            translations: translations,
            onCheckBoxClick: fnIfNotPreview(this.onInputChange),
            onRadioSelect: this.onRadioSelect,
            handleRadioChange: this.handleRadioChange,
            customisedServices: customisedServicesConsent,
            customisedServicesKey: 'customisedServicesConsent',
            customisedServicesError: hasError('customisedServicesConsent'),
            marketing: marketingConsent,
            marketingKey: 'marketingConsent',
            marketingError: hasError('marketingConsent'),
            phoneChecked: phoneConsent,
            phoneKey: 'phoneConsent',
            emailChecked: emailConsent,
            emailKey: 'emailConsent',
            smsChecked: smsConsent,
            smsKey: 'smsConsent',
            onColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
            linkColour: globalStyling.colours.primaryBrandColour,
            marketingLink: marketingLink,
            showUserCommunication: showUserCommunication,
            sendConsentContent: sendConsentContent,
            showFullConsentSection: showFullConsentSection,
            enquiryFormStyles: enquiryFormStyles,
            globalStyling: globalStyling,
            consentRadioOptions: consentRadioOptions,
            enquiryConsentTypes: config.enquiryConsentTypes
          }),
          _react2.default.createElement(
            ActionsContainer,
            null,
            _react2.default.createElement(
              ActionButtonWrapper,
              null,
              _react2.default.createElement(_Global.Button, {
                applyStyle: 'secondary',
                buttonStyle: config.resetButtonStyle ? config.resetButtonStyle.buttonStyle : globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle,
                text: translations.cancelActionButton,
                onClick: history.goBack,
                styleOverride: function styleOverride() {
                  return config.resetButtonStyle && (0, _Global.buttonStyleOverride)(config.resetButtonStyle.font, config.resetButtonStyle.backgroundColour, config.resetButtonStyle.hoverBackgroundColour, config.resetButtonStyle.buttonSize, config.resetButtonStyle.hoverColour, config.resetButtonStyle.border);
                }
              })
            ),
            _react2.default.createElement(
              ActionButtonWrapper,
              null,
              _react2.default.createElement(_Global.Button, {
                applyStyle: 'primary',
                buttonStyle: config.submitButtonStyle ? config.submitButtonStyle.buttonStyle : globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
                text: translations.submitMessageActionButton,
                onClick: this.onSubmitMessage,
                styleOverride: function styleOverride() {
                  return config.submitButtonStyle && (0, _Global.buttonStyleOverride)(config.submitButtonStyle.font, config.submitButtonStyle.backgroundColour, config.submitButtonStyle.hoverBackgroundColour, config.submitButtonStyle.buttonSize, config.submitButtonStyle.hoverColour, config.submitButtonStyle.border);
                }
              })
            )
          ),
          error && _react2.default.createElement(
            Error,
            null,
            translations.somethingWentWrong
          )
        )
      );
    }
  }]);
  return EnquiryForm;
}(_react.Component);

exports.default = EnquiryForm;

EnquiryForm.defaultProps = {
  showUserCommunication: false
};