'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _core = require('@popperjs/core');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Tooltip = require('../VehicleCard/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

var _styledComponentFactory = require('../../Global/styledComponentFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GERMANY_COUNTRY_CODE = 'DE'; /*
                                   Provides vehicle tax information icon and tooltip
                                 */


var baseImage = (0, _styledComponentFactory.createElement)('img');
var TaxIcon = (0, _styledComponents2.default)(baseImage).withConfig({
  displayName: 'GermanTaxDisclaimer__TaxIcon',
  componentId: 'sc-1tuddd6-0'
})(['width:16px;height:16px;', ''], function (_ref) {
  var darkMode = _ref.darkMode;
  return darkMode && 'filter: invert(1);';
});

var GermanTaxDisclaimer = function GermanTaxDisclaimer(_ref2) {
  var countryCode = _ref2.countryCode,
      showSalesTaxDisclaimer = _ref2.showSalesTaxDisclaimer,
      salesTaxDisclaimer = _ref2.salesTaxDisclaimer,
      _ref2$tooltipPlacemen = _ref2.tooltipPlacement,
      tooltipPlacement = _ref2$tooltipPlacemen === undefined ? 'bottom-end' : _ref2$tooltipPlacemen,
      darkMode = _ref2.darkMode,
      styleOverride = _ref2.styleOverride;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    countryCode === GERMANY_COUNTRY_CODE && showSalesTaxDisclaimer && _react2.default.createElement(
      _Tooltip2.default,
      { text: salesTaxDisclaimer, placement: tooltipPlacement },
      _react2.default.createElement(TaxIcon, {
        src: 'https://res.cloudinary.com/motortrak/image/upload/v1708535816/locator/rolls-royce/global/icons/info-taxation.svg',
        alt: 'Tax information icon. Symbol f\xFCr steuerinformationen',
        darkMode: darkMode,
        styleOverride: styleOverride
      })
    )
  );
};

exports.default = GermanTaxDisclaimer;