'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     padding-top: 40px;\n  '], ['\n     padding-top: 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n     ', ';\n  '], ['\n     ', ';\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    width: 100%;\n  '], ['\n    flex-direction: column;\n    width: 100%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n      padding: 100px 0;\n      width: 268px;\n  '], ['\n      padding: 100px 0;\n      width: 268px;\n  ']);
/* eslint-disable react/jsx-indent, no-return-assign */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _filters = require('../../../shared/filters');

var _financeCalculator = require('../../../shared/financeCalculator');

var _printing = require('../../../shared/printing');

var _searchFilter = require('../../../shared/searchFilter');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _vehicle = require('../../../helpers/vehicle');

var _pathBuilders = require('../../../path-builders');

var _Accessories = require('../../../components/RollsRoyce/VDP/Accessories');

var _Accessories2 = _interopRequireDefault(_Accessories);

var _VdpRetailerInfo = require('../../../components/RollsRoyce/VDP/VdpRetailerInfo');

var _VdpRetailerInfo2 = _interopRequireDefault(_VdpRetailerInfo);

var _VdpRetailerMap = require('../../../components/RollsRoyce/VDP/VdpRetailerMap');

var _VdpRetailerMap2 = _interopRequireDefault(_VdpRetailerMap);

var _VehicleCollection = require('../../../components/RollsRoyce/VDP/VehicleCollection');

var _VehicleCollection2 = _interopRequireDefault(_VehicleCollection);

var _VehicleDetails = require('../../../components/RollsRoyce/VDP/VehicleDetails');

var _VehicleDetails2 = _interopRequireDefault(_VehicleDetails);

var _VehicleGallery = require('../../../components/RollsRoyce/VDP/VehicleGallery');

var _VehicleGallery2 = _interopRequireDefault(_VehicleGallery);

var _VehicleInfoBanner = require('../../../components/RollsRoyce/VDP/VehicleInfoBanner');

var _VehicleInfoBanner2 = _interopRequireDefault(_VehicleInfoBanner);

var _VehicleMainInfo = require('../../../components/RollsRoyce/VDP/VehicleMainInfo');

var _VehicleMainInfo2 = _interopRequireDefault(_VehicleMainInfo);

var _VehicleStickyFooter = require('../../../components/RollsRoyce/VDP/VehicleStickyFooter');

var _VehicleStickyFooter2 = _interopRequireDefault(_VehicleStickyFooter);

var _ProvenancePreowned = require('../../../modules/RollsRoyce/ProvenancePreowned/ProvenancePreowned');

var _ProvenancePreowned2 = _interopRequireDefault(_ProvenancePreowned);

var _globalDrawers = require('../../../shared/globalDrawers');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GO_TO_MODULE_RRMC_SEARCH_RESULTS = 'RollsRoyceSearchResults';

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VDP__Container',
  componentId: 'sc-rddx6x-0'
})(['', ';'], _theme2.default.min.large(_templateObject));

var GalleryWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VDP__GalleryWrapper',
  componentId: 'sc-rddx6x-1'
})(['margin:0 auto;width:100%;max-width:1400px;', ';'], _theme2.default.min.large(_templateObject2, function () {
  return _RollsRoyce.mixins.pageGuttering();
}));

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VDP__Wrapper',
  componentId: 'sc-rddx6x-2'
})(['margin:0 auto;width:100%;max-width:1400px;', ';'], function () {
  return _RollsRoyce.mixins.pageGuttering();
});

var GalleryVehicleInfo = _styledComponents2.default.div.withConfig({
  displayName: 'VDP__GalleryVehicleInfo',
  componentId: 'sc-rddx6x-3'
})(['display:flex;flex-direction:row;', ';'], _theme2.default.max.large(_templateObject3));

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'VDP__Logo',
  componentId: 'sc-rddx6x-4'
})(['display:block;width:214px;height:auto;padding:50px 0;margin:0 auto;', ';'], _theme2.default.min.medium(_templateObject4));

var VDP = function (_Component) {
  (0, _inherits3.default)(VDP, _Component);

  function VDP() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, VDP);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = VDP.__proto__ || (0, _getPrototypeOf2.default)(VDP)).call.apply(_ref, [this].concat(args))), _this), Object.defineProperty(_this, 'state', {
      enumerable: true,
      writable: true,
      value: {
        showBanner: false
      }
    }), Object.defineProperty(_this, 'componentDidUpdate', {
      enumerable: true,
      writable: true,
      value: function value(prevProps) {
        var _this$props = _this.props,
            state = _this$props.state,
            shared = _this$props.shared,
            actions = _this$props.actions,
            dispatch = _this$props.dispatch,
            router = _this$props.router,
            _this$props$history$l = _this$props.history.location,
            pathname = _this$props$history$l.pathname,
            hash = _this$props$history$l.hash,
            _this$props$history$l2 = _this$props$history$l.state,
            payload = _this$props$history$l2 === undefined ? { finance: null } : _this$props$history$l2,
            globalFinance = _this$props.finance,
            context = _this$props.state.context,
            hashRouting = _this$props.hashRouting;


        if (prevProps.shared.currencyConversion.exchangeRates !== shared.currencyConversion.exchangeRates) {
          dispatch(actions.updateVehiclePrices({
            vehicle: state.vdpConfig,
            similarVehicles: state.similarVehicles
          }));
        }

        if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language || prevProps.router.navigationHistory[0] !== router.navigationHistory[0]) {
          var vehicleId = (hashRouting ? hash : pathname).match(/(\/)(\d[A-Za-z0-9]*)(-)?/)[2];
          var _finance = payload.finance;

          var financeProductToUse = context || globalFinance && globalFinance.defaultProduct;
          actions.getVehicleInfo({
            vehicleId: vehicleId,
            finance: _finance,
            financeProductToUse: financeProductToUse
          });
        }
      }
    }), Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props2 = _this.props,
            _this$props2$history$ = _this$props2.history.location,
            pathname = _this$props2$history$.pathname,
            hash = _this$props2$history$.hash,
            _this$props2$history$2 = _this$props2$history$.state,
            payload = _this$props2$history$2 === undefined ? { finance: null } : _this$props2$history$2,
            actions = _this$props2.actions,
            dispatch = _this$props2.dispatch,
            featureFlags = _this$props2.featureFlags,
            globalFinance = _this$props2.finance,
            context = _this$props2.state.context,
            config = _this$props2.config,
            financeState = _this$props2.finance,
            hashRouting = _this$props2.hashRouting;


        if (featureFlags.finance) {
          dispatch(_financeCalculator.actions.resetFinanceProduct({
            type: financeState.defaultProduct
          }));
        }

        var vehicleId = (hashRouting ? hash : pathname).match(/(\/)(\d[A-Za-z0-9]*)(-)?/)[2];
        var finance = payload.finance;

        var financeProductToUse = context || globalFinance && globalFinance.defaultProduct;
        actions.getVehicleInfo({
          vehicleId: vehicleId,
          finance: finance,
          financeProductToUse: financeProductToUse
        });

        if (!config.hideSimilarVehicle) {
          actions.getSimilarVehicles({ vehicleId: vehicleId, financeProductToUse: financeProductToUse });
        }
        if (featureFlags.finance) {
          dispatch(_financeCalculator.actions.loadFinanceOptions(vehicleId));
        }
      }
    }), Object.defineProperty(_this, 'termsRange', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props3 = _this.props,
            config = _this$props3.config,
            shared = _this$props3.shared,
            _this$props3$state = _this$props3.state,
            vdpConfig = _this$props3$state.vdpConfig,
            context = _this$props3$state.context,
            finance = _this$props3.finance;

        var marketFinanceOptions = shared.financeCalculator.financeOptions[context || finance && finance.defaultProduct];
        if (marketFinanceOptions) {
          var sortedOptions = (marketFinanceOptions.durationOptions || []).sort(function (a, b) {
            return a - b;
          });
          var exampleDefaultTerm = shared.financeCalculator.financeProductExample[context] && shared.financeCalculator.financeProductExample[context].duration;
          var defaultTerm = (vdpConfig && vdpConfig.finance && vdpConfig || { finance: {} }).finance.duration || sortedOptions[0];
          return {
            id: 'termsRange',
            name: 'term',
            title: config.translations.term,
            unit: config.translations.months,
            step: 0,
            marks: sortedOptions.reduce(function (ac, cv) {
              return (0, _extends4.default)({}, ac, (0, _defineProperty3.default)({}, cv, '' + cv));
            }, {}),
            defaultValue: exampleDefaultTerm || defaultTerm,
            fullRangeValues: {
              min: sortedOptions[0],
              max: sortedOptions[sortedOptions.length - 1]
            }
          };
        }
        return {};
      }
    }), Object.defineProperty(_this, 'isDealerWebsite', {
      enumerable: true,
      writable: true,
      value: function value() {
        var navigationHistory = _this.props.router.navigationHistory;

        if (!navigationHistory) {
          return false;
        }
        return navigationHistory.length >= 2 && navigationHistory[1].includes('dealer_');
      }
    }), _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(VDP, [{
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      var _props = this.props,
          dispatch = _props.dispatch,
          history = _props.history;

      var searchResultsMatcher = /.*\/searchresults\/.*/i;
      var goingToSrp = searchResultsMatcher.test(history.location.pathname);
      if (!goingToSrp) {
        dispatch(_sessionPreferences.actions.updateSessionPreferences('infiniteScroll', false));
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          _props2$state = _props2.state,
          vdpConfig = _props2$state.vdpConfig,
          placeholdingImage = _props2$state.placeholdingImage,
          accessories = _props2$state.accessories,
          collection = _props2$state.collection,
          dispatch = _props2.dispatch,
          shared = _props2.shared,
          pathByModule = _props2.pathByModule,
          config = _props2.config,
          featureFlags = _props2.featureFlags,
          globalStyling = _props2.globalStyling,
          marketInfo = _props2.marketInfo,
          googleAvailable = _props2.shared.google.googleAvailable,
          history = _props2.history;


      var vehicleDetails = vdpConfig;
      var translations = config.translations,
          mainInfoLogo = config.atAGlanceSection.mainInfoLogo;

      var language = marketInfo.locale;

      var goToModule = function goToModule(name, retailer) {
        var path = (0, _pathBuilders.pathWithDealerID)(pathByModule(name), retailer.name, config.specialDealerRedirection ? retailer.oemId : retailer.id, language, config.specialDealerRedirection && featureFlags.dealerUrl);

        window.open(window.location.origin + '/' + path, config.newTab);
      };

      var resetFilters = function resetFilters() {
        _this2.props.dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
        _this2.props.dispatch(_searchFilter.actions.resetFilters());
        _this2.props.dispatch(_filters.actions.resetFilters());
      };

      var setShowBanner = function setShowBanner(showBanner) {
        _this2.setState(function () {
          return { showBanner: showBanner };
        });
      };

      var printVehiclePdf = function printVehiclePdf() {
        return dispatch(_printing.actions.printVehicleInfo(vehicleDetails, translations, config));
      };
      var printingState = shared.printing;

      var getVehicleTitle = function getVehicleTitle() {
        if (translations.customSeoTitle) {
          return (0, _vehicle.translateTemplateWithVehicleData)('customSeoTitle', config.customSeoProps, vehicleDetails, translations);
        }
        return config.hideYear ? vehicleDetails.description : vehicleDetails.registration + ' ' + vehicleDetails.description;
      };

      var getVehicleDescription = function getVehicleDescription() {
        if (translations.customSeoDescription) {
          return (0, _vehicle.translateTemplateWithVehicleData)('customSeoDescription', config.customSeoProps, vehicleDetails, translations);
        }
        return '';
      };

      var navigateToEnquiryForm = function navigateToEnquiryForm() {
        dispatch(_globalDrawers.actions.toggleEnquireDrawer(vehicleDetails));
      };

      var makeAnEnquiry = function makeAnEnquiry(e) {
        e.preventDefault();
        if (config.enquiryCtaPath) {
          document.getElementById(config.enquiryCtaPath).scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        } else {
          navigateToEnquiryForm();
        }
      };

      return vehicleDetails ? _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(
          GalleryWrapper,
          null,
          _react2.default.createElement(
            _reactHelmet.Helmet,
            null,
            _react2.default.createElement(
              'title',
              null,
              getVehicleTitle()
            ),
            _react2.default.createElement('meta', { name: 'description', content: getVehicleDescription() })
          ),
          _react2.default.createElement(
            GalleryVehicleInfo,
            null,
            _react2.default.createElement(_VehicleGallery2.default, {
              globalStyling: globalStyling,
              featureFlags: featureFlags,
              vehicleImages: {
                slides: vehicleDetails.images.map(function (i) {
                  return {
                    id: i,
                    image: i
                  };
                })
              },
              vehicleInfo: vehicleDetails,
              translations: config.translations,
              placeholdingImage: placeholdingImage,
              rotateGallery: true,
              isLooping: false,
              config: config
            }),
            _react2.default.createElement(_reactWaypoint2.default, {
              onPositionChange: function onPositionChange(_ref2) {
                var currentPosition = _ref2.currentPosition;

                if (currentPosition === _reactWaypoint2.default.above) {
                  setShowBanner(true);
                } else {
                  setShowBanner(false);
                }
              }
            }),
            _react2.default.createElement(_VehicleMainInfo2.default, {
              vehicle: vehicleDetails,
              config: config,
              translations: config.translations,
              locale: marketInfo.locale,
              printVehiclePdf: printVehiclePdf,
              printingState: printingState,
              makeAnEnquiry: makeAnEnquiry,
              globalStyling: globalStyling
            })
          )
        ),
        featureFlags.showVehicleDetailsCollections && collection && _react2.default.createElement(
          _react2.default.Fragment,
          null,
          vehicleDetails.approved && _react2.default.createElement(Logo, { src: mainInfoLogo, alt: 'Vehicle approved image' }),
          _react2.default.createElement(_VehicleCollection2.default, {
            title: collection.content.title,
            body: collection.content.body,
            features: collection.content.features,
            textColor: collection.theme.textColor,
            images: collection.content.images,
            backgroundGradient: collection.theme.backgroundGradient
          })
        ),
        _react2.default.createElement(
          Wrapper,
          null,
          _react2.default.createElement(_VehicleDetails2.default, {
            vehicle: vehicleDetails,
            config: config,
            translations: config.translations,
            locale: marketInfo.locale,
            globalStyling: globalStyling,
            showApprovedLogo: !featureFlags.showVehicleDetailsCollections || !collection
          })
        ),
        _react2.default.createElement(_VehicleInfoBanner2.default, {
          showBanner: this.state.showBanner,
          vehicleDetails: vehicleDetails,
          config: config,
          history: history,
          handleZeroPriceAsText: config.handleZeroPriceAsText,
          navigateToEnquiryForm: makeAnEnquiry
        }),
        _react2.default.createElement(_Accessories2.default, {
          vehicle: vehicleDetails,
          translations: config.translations,
          config: config,
          accessories: accessories,
          language: marketInfo.locale,
          modelVariant: vehicleDetails.modelVariant
        }),
        !config.hideProvenanceSection && _react2.default.createElement(_ProvenancePreowned2.default, { config: config }),
        _react2.default.createElement(_VdpRetailerInfo2.default, {
          retailer: vehicleDetails.retailerInformation,
          translations: translations,
          viewRetailerStock: function viewRetailerStock(retailer) {
            resetFilters();
            goToModule(GO_TO_MODULE_RRMC_SEARCH_RESULTS, retailer);
          },
          config: config
        }),
        _react2.default.createElement(_VdpRetailerMap2.default, {
          googleAvailable: googleAvailable,
          mapKey: this.props.maps.apiKey,
          retailer: vehicleDetails.retailerInformation,
          config: config
        }),
        _react2.default.createElement(_VehicleStickyFooter2.default, {
          onEnquireClick: makeAnEnquiry,
          onPhoneClick: function onPhoneClick() {
            window.location.href = 'tel:' + vehicleDetails.retailerInformation.phone;
          },
          config: config
        })
      ) : null;
    }
  }]);
  return VDP;
}(_react.Component);

exports.default = VDP;